import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import failedIcon from '../../assets/images/failed-icon.svg';
import successIcon from '../../assets/images/success-icon.svg';
import { useStore } from '../../context/StoreContext';
import OrderDetails from '../ViewOrder';

const OrderConfirmation = () => {
  const [successOrders, setSuccessOrders] = useState([]);
  const [failedOrders, setFailedOrders] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const { storeOrderStatus } = useStore();


  useEffect(() => {
    const placedOrders = storeOrderStatus;
    const success = [];
    const failed = [];
    placedOrders.forEach(order => {
      if (order.status === 'failure') {
        failed.push(order.brandStore);
      } else {
        success.push(order.brandStore);
        setOrderId(order.order_id);
      }
    });

    setSuccessOrders(success);
    setFailedOrders(failed);
  }, [storeOrderStatus]);

  return (
    <div className="min-h-screen bg-white p-4 mt-8">
      <div className="max-w-3xl mx-auto">
        <div className="space-y-4">
          {successOrders?.length > 0 &&
            <><div className="bg-white border border-gray-200 rounded-lg p-6 text-center bg-[#FCF6F2]">
              <img src={successIcon} alt="Success" className="h-16 w-16 mx-auto mb-4" />
              <h2 className="text-2xl font-semibold mb-2">Thank you for your order</h2>
            </div>
              <OrderDetails id={orderId} />
            </>
          }

          {successOrders?.length <= 0 && failedOrders.map((brand, index) => (
            <div key={index} className="bg-white border border-gray-200 rounded-lg p-6 text-center">
              <img src={failedIcon} alt="Failed" className="h-16 w-16 mx-auto mb-4" />
              <h2 className="text-2xl font-semibold mb-2">Oh no, order couldn't be processed</h2>
              <p className="text-gray-600">
                There was an issue when trying to process the order. These items are still in your shopping cart. Please try again.
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;