import React, { useState, useEffect } from 'react';
import Banner from '../../components/Banner';
import ProductCard from '../../components/ProductCard';
import SkeletonLoader from '../../components/SkeletonLoader';
import { useStore } from '../../context/StoreContext';
import axios from '../../utilities/axios';
import * as API_END_POINT from '../../utilities/constant';

const Home = () => {
    const { storeDetails } = useStore();
    const [products, setProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [categoryId, setCategoryId] = useState(null);
    const [limit, setLimit] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0);


    const handlePageChange = () => {
        const page = currentPage;
        setCurrentPage(page + 1);
        setOffset((page + 1) * limit);
    };


    useEffect(() => {
        if (storeDetails?.store_name) {
            setOffset(0)
            setCurrentPage(0)
            getStoreProducts(false);
        }
    }, [storeDetails, categoryId]);

    useEffect(() => {
        if (offset > 0) {
            getStoreProducts(true);
        }
    }, [offset]);

    const getStoreProducts = async (newOffset = false) => {
        setLoadingProducts(true);
        try {
            let query = {};
            let filter = [];

            if (categoryId) {
                query = { category_id: String(categoryId) }
            }

            const payload = {
                paging: { limit: limit, offset: newOffset ? offset : 0 },
                query: query,
                filter: filter,
            };
            const response = await axios.post(API_END_POINT.STORE_PRODUCTS, payload);
            setCount(response.data.data.count)

            setProducts((prevProducts) =>
                newOffset ? [...prevProducts, ...response.data.data.rows] : response.data.data.rows
            );
            // setProducts(response.data.data.rows);
        } catch (err) {
            console.error('Error fetching products', err);
        } finally {
            setLoadingProducts(false);
        }
    };

    return (
        <main className="container mx-auto px-8 py-8">
            <Banner />
            <div className="mb-8">
                <nav className="flex justify-center">
                    <button className={`px-4 py-2 font-semibold text-navy50 ${categoryId === null && 'border-b-2 border-[#d46f2c]'}`} onClick={() => setCategoryId(null)}>
                        SHOP ALL
                    </button>
                    {storeDetails?.StorefrontCategories?.sort((a, b) => a.sequence - b.sequence)?.map((category) => (
                        <button className={`px-4 py-2 font-light text-navy50 ${categoryId === category?.id && 'border-b-2 border-[#d46f2c]'}`} key={category?.id} onClick={() => setCategoryId(category?.id)}>
                            {category?.name}
                        </button>
                    ))}
                </nav>
            </div>


            {loadingProducts
                ? <>
                    <div className="max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">{Array.from({ length: 8 }).map((_, index) => (
                        <SkeletonLoader type="productCard" key={index} />
                    ))} </div></>
                : (
                    <>
                        {
                            products?.length > 0 ?
                                <>
                                    <div className="max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                                        {
                                            products?.sort((a, b) => a?.store_products[0]?.sequence - b?.store_products[0]?.sequence).map((product) => (
                                                <ProductCard key={product.id} product={product} />
                                            ))
                                        }
                                    </div>
                                </> :
                                (
                                    <div>
                                        <p className='font-semibold text-xl text-navy50 text-center py-20'>There are no products in this collection.</p>
                                    </div>
                                )
                        }

                    </>
                )}


            {
                count > products?.length &&
                <div className="text-center mt-20">
                    <button onClick={handlePageChange} className="text-navy50 px-6 py-2 rounded-full font-medium text-[1.1rem]">
                        WANT TO SEE MORE?
                    </button>
                </div>
            }


            <div className="mb-8 mt-10">
                <nav className="flex justify-center">
                    <button className={`px-4 py-2 font-semibold text-navy50 ${categoryId === null && 'border-b-2 border-[#d46f2c]'}`} onClick={() => setCategoryId(null)}>
                        SHOP ALL
                    </button>
                    {storeDetails?.StorefrontCategories?.sort((a, b) => a.sequence - b.sequence)?.map((category) => (
                        <button className={`px-4 py-2 font-light text-navy50 ${categoryId === category?.id && 'border-b-2 border-[#d46f2c]'}`} key={category?.id} onClick={() => setCategoryId(category?.id)}>
                            {category?.name}
                        </button>
                    ))}
                </nav>
            </div>
        </main>
    );
};

export default Home;
