import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { StoreProvider } from './context/StoreContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProductPage from './pages/ProductDetail';
import Checkout from './pages/Checkout';
import Payment from './pages/Payment';
import OrderConfirmation from './pages/OrderConfirmation';
import ViewOrders from './pages/ViewOrder';
import StoreNotExist from './pages/NoStore';
import OrderFailure from './pages/OrderFailure';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const generateRandomId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' + new Date().valueOf();
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const setupPriorityEnvironment = () => {
    let src = "https://js.sandbox.prioritypassport.com/";
    if(process.env.REACT_APP_API_ENDPOINT == 'https://backend2.shopdotapp.com'){
      src = "https://js.prioritypassport.com/";
    }
    const script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);
  };

  useEffect(() => {
    setupPriorityEnvironment();
    const device_id = localStorage.getItem('device_id');
    if (!device_id) {
      const new_device_id = generateRandomId(6);
      localStorage.setItem('device_id', new_device_id);
    }
  }, []);

  return (
    <StoreProvider>
      <Router>
        <ScrollToTop />
        <div className="min-h-screen flex flex-col">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/product/:productId" element={<ProductPage />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/checkout/payment-details" element={<Payment />} />
              <Route path="/order-confirmation" element={<OrderConfirmation />} />
              <Route path="/order-failure" element={<OrderFailure />} />

              <Route path="/view-order/:id" element={<ViewOrders />} />
              <Route path="/store-not-available" element={<StoreNotExist />} />
              <Route path="/*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </StoreProvider>
  );
}

export default App;