import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PatternFormat } from 'react-number-format';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import axios from '../../utilities/axios';
import * as API_END_POINT from '../../utilities/constant';
import visaLogo from '../../assets/images/visa-logo.svg';
import masterCardLogo from '../../assets/images/mastercard.svg';
import unionpayLogo from '../../assets/images/unionpay.svg';
import maestroLogo from '../../assets/images/maestro.svg';
import discoverLogo from '../../assets/images/discover.svg';
import amexLogo from '../../assets/images/amex.svg';

import OrderListing from '../OrderListing';
import { useStore } from '../../context/StoreContext';
import cardValidator from 'card-validator';
let cvvLength = 3;
const FINIX_API_KEY = process.env.REACT_APP_FINIX_API_KEY;
const FINIX_ENV_TYPE = process.env.REACT_APP_FINIX_ENV_TYPE;
const FINIX_MERCHANT_ID = process.env.REACT_APP_FINIX_MERCHANT_ID;


const schema = yup.object().shape({
    holderName: yup.string().required('Please fill this field').matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, 'please enter letters only and make sure there is not a space at beginning and end.'),
    cardNumber: yup
        .string()
        .required('Please fill this field')
        .test('cardNumber', 'Incorrect card number', (value) => {
            if (value && typeof value === 'string') {
                const cardInfo = cardValidator.number(value);
                cvvLength = (cardInfo.card && cardInfo.card.type === "american-express" ? 4 : 3);
                return cardInfo.isValid;
            }
            return false;
        }),
    expiryMonth: yup.object().nullable().required('Please fill this field').test(
        'checkExpiryMonth',
        'Previous date followed by the current date is not allowed.',
        function (data) {
            if (this.parent?.expiryYear?.value) {
                const date = new Date();
                const currentYear = date?.getFullYear();
                const currentMonth = date?.getMonth() + 1;
                if (currentYear === this.parent?.expiryYear?.value && data?.value < currentMonth) {
                    return false;
                }
            }
            return true;
            //   return parseInt(this.parent.expiryYear) > parseInt(value);
        }
    ),
    expiryYear: yup.object().nullable().required('Please fill this field').test(
        'checkExpiryYear',
        'Previous date followed by the current date is not allowed.',
        function (data) {
            if (this.parent?.expiryMonth?.value) {
                const date = new Date();
                const currentYear = date?.getFullYear();
                const currentMonth = date?.getMonth() + 1;
                if (this.parent?.expiryMonth?.value < currentMonth && data?.value == currentYear) {
                    return false;
                }
            }
            return true;
            //   return parseInt(this.parent.expiryYear) > parseInt(value);
        }
    ),
    cvv: yup
        .string()
        .required('Please fill this field')
        .required('Please fill this field').test("cvv", "Invalid CVV", (value) => {
            const cvvValidation = cardValidator.cvv(value, cvvLength);
            return cvvValidation.isValid;
        }),
    addressLine1: yup.string().required('Please fill this field').min(4).max(40),
    state: yup.object().nullable().required('Please fill this field'),
    city: yup.string().required('Please fill this field').matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, 'please enter letters only and make sure there is not a space at beginning and end.').min(2).max(50),
    zip: yup
        .string()
        .nullable()
        .required('Please fill this field')
        .matches(/^\d{5}$/, 'Zip code should contain 5 digits'),

});

const PaymentDetails = () => {
    const navigate = useNavigate();
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [states, setStates] = useState([]);
    const [isOrderPlacing, setIsOrderPlacing] = useState(false);
    const { customerInfo, setCustomerInfo, brandsCartCalc, setStoreOrderStatus } = useStore();
    const [state, setState] = useState([]);

    const { register, handleSubmit, control, setValue, formState: { errors }, trigger } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const fetchStates = async (countryId) => {
        try {
            const response = await axios.get(API_END_POINT.STORE_STATES(countryId));
            if (response && response.data && response.data.code === 200) {
                setStates(response.data.data);
            }
        } catch (err) {
            console.error('Error fetching states:', err);
        }
    };

    const getOrderTotalAmount = () => {
        return brandsCartCalc.reduce((total, brand) => total + brand.subtotal, 0).toFixed(2);
    };

    useEffect(() => {
        if (states?.length > 0) {
            const s = [];
            states?.map(v => {
                s.push({ value: v?.code, label: v?.name });
            })
            setState(s);
        }
    }, [states])

    useEffect(() => {
        fetchStates(1);
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 11 }, (_, i) => currentYear + i);
        const months = Array.from({ length: 12 }, (_, i) => i + 1);
        setYearList(years.map(year => ({ value: year, label: year.toString() })));
        setMonthList(months.map(month => ({ value: month, label: month.toString().padStart(2, '0') })));
    }, []);

    useEffect(() => {
        const onSubmit = async () => {
            setIsOrderPlacing(true);
            form.submit(FINIX_ENV_TYPE, FINIX_API_KEY, async function (err, res) {
                const tokenData = res.data || {};
                const token = tokenData.id;

                if (token) {
                    window.Finix.Auth(FINIX_ENV_TYPE, FINIX_MERCHANT_ID, async (sessionKey) => {

                        const body = {
                            first_name: customerInfo.firstName,
                            last_name: customerInfo.lastName,
                            email: customerInfo.contactEmail,
                            phone_number: customerInfo.phoneNumber,
                            shipping_address: {
                                address_1: customerInfo.addressLine1,
                                address_2: customerInfo.addressLine2 ? customerInfo.addressLine2 : null,
                                country: customerInfo.country.label,
                                city: customerInfo.city,
                                state: customerInfo.state.label,
                                zipcode: customerInfo.zip,
                            },
                            is_billing_address_same: true,
                            billing_address: {
                                address_1: customerInfo.addressLine1,
                                address_2: customerInfo.addressLine2 ? customerInfo.addressLine2 : null,
                                country: customerInfo.country.label,
                                city: customerInfo.city,
                                state: customerInfo.state.label,
                                zipcode: customerInfo.zip,
                            },
                            token: token,
                            fraud_session_id: sessionKey,
                            idempotency_id:localStorage.getItem('idempotency_id'),
                            total_amount: getOrderTotalAmount(),
                        };
                        body.device_id = localStorage.getItem('device_id');
                        
                        try{
                            const response = await axios.post(API_END_POINT.STORE_PLACE_ORDER, body);
                            setIsOrderPlacing(false);
                            if (response.data.success) {
                                setStoreOrderStatus(response.data.data);
                                navigate('/order-confirmation');
                            } else {
                                navigate('/order-failure');
                                console.error('Error placing order:', response.data.message);
                            }
                        }catch(error)
                        {
                            setIsOrderPlacing(false);
                            navigate('/order-failure');
                        }
                       
                    })
                } else {
                    setIsOrderPlacing(false);

                    console.error('Failed to get token from Finix');
                }
            });
        }

        const form = window.Finix.CardTokenForm('form', {
            showAddress: true,
            onSubmit,
        });
    }, [])



    const checkAddress = async (event) => {
        if (event.target.checked) {
            setValue('addressLine1', customerInfo.addressLine1);
            setValue('addressLine2', customerInfo.addressLine2);
            setValue('country', customerInfo.country);
            setValue('state', customerInfo.state);
            setValue('city', customerInfo.city);
            setValue('zip', customerInfo.zip);
        } else {
            setValue('addressLine1', '');
            setValue('addressLine2', '');
            setValue('country', { value: 1, label: 'United States' });
            setValue('state', '');
            setValue('city', '');
            setValue('zip', '');
        }

        await trigger(['addressLine1', 'addressLine2', 'country', 'state', 'city', 'zip']);
    };

    const getSelectBoxStyle = (isError = false) => {
        return {
            control: (styles) => {
                return {
                    ...styles,
                    borderColor: isError ? '#eb5757 !important' : '#cccccc',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                        boxShadow: 'none !important',
                    },
                };
            },
            container: (style) => {
                return {
                    ...style,
                    marginTop: '5px',
                    marginRight: '1px',
                };
            },
        };
    };

    const getSelectBoxTheme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#fbf5f0',
            primary: '#bd6f34',
        },
    });


    return (
        <div className="relative payment-details-container">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <h1 className="text-2xl font-bold mb-6 text-gray-800">Payment details</h1>
                <div className="payment-details-layout">
                    <div className="order-summary space-y-4 border border-gray-300 rounded-lg p-4">
                        <OrderListing isFromPayment='true' />
                    </div>
                    <div className="payment-form">
                        <div className="bg-white border border-gray-300 rounded-lg p-4 mb-6">
                            <div className="flex justify-between items-center mb-4 border-b border-gray-300 pb-3">
                                <h2 className="text-lg font-semibold text-gray-800">Customer information</h2>
                                <button
                                    className="text-white bg-gray-700 hover:bg-gray-800 px-3 py-1 rounded text-sm flex items-center"
                                    onClick={() => navigate('/checkout')}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>
                                    Edit
                                </button>
                            </div>

                            <div className="text-sm space-y-1 text-gray-600">
                                <p>{customerInfo.contactEmail}</p>
                                <p>{customerInfo.firstName} {customerInfo.lastName}</p>
                                <p>{customerInfo.addressLine1}</p>
                                <p>{customerInfo.addressLine2}</p>
                                <p>{customerInfo.city}, {customerInfo.state.label}, {customerInfo.country.label}, {customerInfo.zip}</p>
                                <p>{customerInfo.phoneNumber}</p>
                            </div>
                        </div>
                        <div className="bg-white border border-gray-300 rounded-lg p-4">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-lg font-semibold text-gray-800">Payment</h2>
                                <div className="flex space-x-2">
                                    <img src={visaLogo} alt="Visa" className="h-4 sm:h-6" />
                                    <img src={masterCardLogo} alt="Mastercard" className="h-4 sm:h-6" />
                                    <img src={discoverLogo} alt="Discover" className="h-4 sm:h-6" />
                                    <img src={amexLogo} alt="AMEX" className="h-10 sm:h-6" />
                                </div>
                            </div>
                            <div id='form' />
                        </div>
                    </div>
                </div>
            </div>
            {isOrderPlacing && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="loader mb-4 mx-auto"></div>
                        <p className="text-lg font-semibold">Processing your order...</p>
                        <p className="text-sm text-gray-600 mt-2">Please do not refresh the page or close the window.</p>
                    </div>
                </div>
            )}

            <style jsx>{`
                .loader {
                    border: 4px solid #f3f3f3;
                    border-top: 4px solid #f97316;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    animation: spin 1s linear infinite;
                }
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                      @media (max-width: 1023px) {
                    .payment-details-layout {
                        display: flex;
                        flex-direction: column;
                    }
                    .order-summary {
                        order: -1;
                        margin-bottom: 2rem;
                    }
                }
                @media (min-width: 1024px) {
                    .payment-details-layout {
                        display: flex;
                        flex-direction: row;
                        gap: 2rem;
                    }
                    .payment-form {
                        width: 60%;
                        order: -1;
                    }
                    .order-summary {
                        width: 40%;
                    }
                }
            `}</style>

        </div>


    );
};

export default PaymentDetails;