import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import failedIcon from '../../assets/images/failed-icon.svg';
import successIcon from '../../assets/images/success-icon.svg';
import { useStore } from '../../context/StoreContext';
import OrderDetails from '../ViewOrder';

const OrderFailure = () => {
  const [successOrders, setSuccessOrders] = useState([]);
  const [failedOrders, setFailedOrders] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const { storeOrderStatus } = useStore();


   
  return (
    <div className="min-h-screen bg-white p-4 mt-8">
      <div className="max-w-3xl mx-auto">
        <div className="space-y-4">
           

         
            <div  className="bg-white border border-gray-200 rounded-lg p-6 text-center">
              <img src={failedIcon} alt="Failed" className="h-16 w-16 mx-auto mb-4" />
              <h2 className="text-2xl font-semibold mb-2">Oh no, order couldn't be processed</h2>
              <p className="text-gray-600">
                There was an issue when trying to process the order. These items are still in your shopping cart. Please try again.
              </p>
            </div>
     
        </div>
      </div>
    </div>
  );
};

export default OrderFailure;