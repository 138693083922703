import React from 'react';

const TextWithEllipsis = ({ text, limit = 25 }) => {
  const maxLength = limit;
  const isTruncated = text.length > maxLength;
  const displayText = isTruncated ? text.slice(0, maxLength) + "..." : text;

  return (
    <div className="relative group">
      <span className='text-navy50'>{displayText}</span>
      {isTruncated && (
        <div className="absolute left-0  mb-2 w-[200px] p-2 bg-gray-700 text-white text-[9px] rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-1000 leading-3 ">
          {text}
        </div>
      )}
    </div>
  );
};

export default TextWithEllipsis;
