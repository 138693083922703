import React, { useMemo } from 'react';
import { useStore } from '../../context/StoreContext';

const OrderListing = ({ isFromPayment = false }) => {
    const { cartItems, storeDetails, loading, setBrandsCartCalc } = useStore();

    const getShippingDays = (days) => {
        const times = [
            { name: "3-7 days", code: "3-7_days" },
            { name: "0-7 days", code: "0-7_days" },
            { name: "8-14 days", code: "8-14_days" },
            { name: "15-21 days", code: "15-21_days" },
            { name: ">21 days", code: "gt21_days" },
        ];
        return times?.find((item) => item?.code === days)?.name;
    };

    const getDiscountValue = (price, brand_discount) => {
        let discount = brand_discount?.discount_percentage ? parseFloat(brand_discount?.discount_percentage) : 0;
        let start_date = brand_discount?.start_date;
        let end_date = brand_discount?.end_date;
        let discount_percentage = 0;

        if (discount && isCurrentTimeWithinDateRange(start_date, end_date) &&
            storeDetails?.user?.subscription?.recurly_plan_code !== 'retailer-starter-monthly-plan') {
            discount_percentage = discount;
        }

        const prices = price?.split('-').map(v => parseFloat(v));
        const discountedPrices = prices.map(v =>
            discount_percentage ? (v - (v * discount_percentage / 100)).toFixed(2) : v.toFixed(2)
        );

        return parseFloat(discountedPrices[0]); // Return the first price if there are multiple
    };

    const isCurrentTimeWithinDateRange = (startDate, endDate) => {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = endDate ? new Date(endDate) : new Date(8640000000000000);
        return currentDate >= start && currentDate <= end;
    };

    const isProductUnavailable = (productsByBrand, item) => {
        const pendingStock = item.product_variants.allowed_inventory_quantity;
        const inventoryTracked = item.product_variants.inventory_management;

        if (!inventoryTracked) return false;
        if (pendingStock === 0) return true;
        if (item.product_variants.status !== '1') return true;
        if (!item.products || item.products.status !== 1) return true;
        if (!productsByBrand?.brand_user?.access_token?.is_active) return true;
        if (item?.product_variants?.is_deleted) return true;
        if (item?.products?.store_products?.length === 0) return true;

        return false;
    };

    const calcTotal = useMemo(() => {
        const calcArr = [];
        let status = false;

        cartItems?.forEach((brand) => {
            let totalMsrp = 0;
            let tax = 0.00;
            let totalQty = 0;
            const shippingFee = brand?.brand_user?.brand_details?.shipping_rate?.shipping_cost || 0;
            let transactionFee = 0;

            brand.cart_items?.forEach((item) => {
                if (
                    !isProductUnavailable(brand, item) &&
                    item.cart_quantity > 0 &&
                    item.product_variants.allowed_inventory_quantity >= item.cart_quantity
                ) {
                    let discountValue = getDiscountValue(item.product_variants.msrp_price, item?.products?.user?.brand_discount);

                    totalMsrp += item.cart_quantity * discountValue;
                    totalQty += item.cart_quantity;
                } else if (item.product_variants.inventory_management) {
                    status = true;
                }
            });

            if (transactionFee > 0) {
                transactionFee = ((totalMsrp + shippingFee) * transactionFee) / 100;
            }

            if (isFromPayment && brand?.sales_tax_rate) {
                tax = brand?.sales_tax_rate * (totalMsrp + shippingFee);
            }

            const shippingWithTax = isFromPayment ? shippingFee + tax : 0;

            calcArr.push({
                brand_id: brand?.brand_user?.id,
                store_name: brand?.brand_user?.brand_details?.store_name,
                total_msrp: totalMsrp ? totalMsrp : 0,
                total_qty: totalMsrp ? totalQty : 0,
                total_shipping: totalMsrp ? shippingFee : 0,
                total_transaction_fee: totalMsrp ? transactionFee : 0,
                tax: totalMsrp ? tax : 0,
                subtotal: totalMsrp ? totalMsrp + shippingWithTax : 0,
            });
        });

        setBrandsCartCalc(calcArr);

        return { calcArr, status };
    }, [cartItems, isFromPayment]);

    const orderTotal = useMemo(() => {
        return calcTotal.calcArr.reduce((total, brand) => total + brand.subtotal, 0);
    }, [calcTotal]);

    if (loading) {
        return (
            <div className="max-w-3xl mx-auto space-y-4">
                {[1, 2].map((_, index) => (
                    <div key={index} className="bg-gray-100 p-6 rounded-lg shadow animate-pulse">
                        <div className="h-4 bg-gray-300 rounded w-1/4 mb-4"></div>
                        {[1, 2, 3].map((_, itemIndex) => (
                            <div key={itemIndex} className="flex items-center space-x-4 mb-4">
                                <div className="w-12 h-12 bg-gray-300 rounded-md"></div>
                                <div className="flex-grow">
                                    <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                                    <div className="h-3 bg-gray-300 rounded w-1/2"></div>
                                </div>
                                <div className="h-4 bg-gray-300 rounded w-16"></div>
                            </div>
                        ))}
                        <div className="mt-4 pt-4 border-t border-gray-300">
                            <div className="flex justify-between items-center">
                                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
                                <div className="h-4 bg-gray-300 rounded w-1/6"></div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="px-2 py-2 rounded-lg animate-pulse">
                    <div className="flex justify-between items-center">
                        <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                        <div className="h-6 bg-gray-300 rounded w-1/6"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-3xl mx-auto space-y-4">
            {calcTotal.calcArr.map((brandCalc, index) => (
                <div key={index} className="bg-gray-100 p-6 rounded-lg shadow">
                    <h2 className="text-sm font-semibold mb-4">{brandCalc.store_name}</h2>
                    <hr />
                    {cartItems[index].cart_items.map((item, itemIndex) => (
                        <div key={itemIndex} >
                            <div className="flex items-center space-x-4 mb-4 mt-4" >
                                <div className="relative">
                                    <img
                                        src={item.product_variants.image || item.products.product_images.find(image => image.is_main === '1')?.src}
                                        alt={item.products.title}
                                        className="w-16 h-16 object-cover rounded-md"
                                    />
                                    <span className="absolute -top-2 -right-2 bg-orange-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                                        {item.cart_quantity}
                                    </span>
                                </div>
                                <div className="flex-grow">
                                    <h4 className="font-medium text-sm">
                                        {item.products.title}
                                    </h4>

                                </div>
                                <span className="font-semibold text-sm">
                                    ${(item.cart_quantity * getDiscountValue(item.product_variants.msrp_price, item.products.user.brand_discount)).toFixed(2)}
                                </span>
                            </div>
                            <div>
                                {item.product_variants.title !== "Default Title" && (
                                    <span className="text-sm text-gray-600"> ({item.product_variants.title})</span>
                                )}
                                <p className="text-sm text-gray-600 mt-1">
                                    Ships Within: <b>{getShippingDays(item.products.shipping_time)}</b>
                                </p>
                                {item.customizable_note && (
                                    <p className="text-sm text-gray-600 mt-1">
                                        Customized Tag Name: <b>{item.customizable_note}</b>
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                
                    <div className="mt-0 pt-4 border-gray-300 space-y-2">
                    <hr />
                        <div className="flex justify-between items-center text-sm">
                            <span>Shipping</span>
                            <span>${brandCalc.total_shipping.toFixed(2)}</span>
                        </div>
                        <div className="flex justify-between items-center font-semibold text-sm">
                            <span>Brand total</span>
                            <span>${brandCalc.subtotal.toFixed(2)}</span>
                        </div>
                    </div>
                    <p className="mt-4 text-sm text-gray-600 italic">
                        This item is shipped from and sold by <span className='font-semibold'>{brandCalc.store_name}</span>.
                    </p>
                </div>
            ))}
            <div className="px-2 py-2 rounded-lg">
                <div className="flex justify-between items-center font-semibold text-lg">
                    <span>Subtotal</span>
                    <span>${orderTotal.toFixed(2)}</span>
                </div>
            </div>
        </div>
    );
};

export default OrderListing;